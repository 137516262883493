/***********Editor CSS*************/
.editorToolbar {
  border: 1px solid #ccc;
  background-color: #f4f4f4;
  padding: 2px;
}

.editorContent {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 600px;
  background-color: #ffffff;
}

.editorButton {
  margin-right: 3px;
  padding: 0;
  cursor: pointer;
  border: solid 1px;
  background-color: whitesmoke;
  border-radius: 3px;
  border-color: lightgray;
  height: 30px;
  width: 30px;
  text-align: center;
}

.editorButton:hover {
  background-color: #bbb;
}

.cl-py-2 {
  padding-top: .5rem !important;
  padding-bottom: .6rem !important;
}

.file-input-wrapper {
  position: relative;
  display: inline-block;
}

.file-input-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
}