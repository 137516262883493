.paging-steps {
  font-family: "HelveticaNeueLTPro-Lt", sans-serif;
  gap: calc(10px + 5vw);
}
.paging-steps .step-num {
  min-width: 40px;
  height: 40px;
  color: #fff;
  background-color: var(--hilight);
  font-size: 16px;
  text-decoration: none;
  border-radius: 50% !important;
}
.paging-steps .inactive {
  border: 2px solid;
  background-color: #FFF;
  color: #000;
  border-color: var(--hilight);
}
.paging-steps .step-title {
  line-height: 1.2;
}